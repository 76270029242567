.pill-list {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px 4px;
  align-self: stretch;
  flex-wrap: wrap;
  span.pill {
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 10px; 
    border-radius: 999px;
    border: 1px solid #272727;
    background: #141414;
    color: #B0B0B0;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  span.blue-pill {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px; 
    border-radius: 999px;
    background: #081A37;
    color: #009EE7;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: default;
    svg {
      width: 12px;
      height: 12px;
      path { 
        fill: #009EE7; 
      } 
    }
  }
}

span.pill, a.pill {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 10px; 
  border-radius: 999px;
  border: 1px solid #272727;
  background: #141414;
  color: #B0B0B0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  svg {
    width: 12px;
    height: 12px;
    path { 
      fill: #B0B0B0; 
    } 
  }
  
  &.blue-pill {
    background: #081A37;
    color: #009EE7;
    svg {
      path { 
        fill: #009EE7; 
      } 
    }
    
    &.active {
      color: #081A37;
      background: #009EE7;
      svg {
        path { 
          fill: #081A37; 
        } 
      }
    }
  }
  
  &.pill-with-badge {
    padding: 4px 4px 4px 16px;
  }

  .pill-badge {
    display: flex;
    height: 24px;
    min-width: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 4px;
    border-radius: 999px;
    background: #000;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

}



.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #009EE7;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

.button {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  text-align: center;
  font-family: Arvo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
  
  &.button-primary {
    color: #000;
    background: #F0FF14;
    box-shadow: -4px 4px 0px 0px #F5148E, 4px -4px 0px 0px #009EE7;
    margin: 0px 4px;
    svg path {
      fill: #000;
    }
  }
  &.button-secondary {
    padding: 12px;
    gap: 8px;
    background-color: #081A37;
    color: #009EE7;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    svg path {
      fill: #009EE7;
    }
  }

  
  &.button-tertiary {
    padding: 12px;
    gap: 8px;
    background-color: transparent;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    svg path {
      fill: #FFF;
    }
  }
  
  &.small {
    padding: 8px 12px;
  }
  
  &.no-pointer {
    cursor: default;
  }
}

.button-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

.search-container {
  width: 350px;
  display: flex;
  position: relative;
  align-items: center;
  
  .search {
    display: flex;
    width: 364px;
    padding: 12px 16px 12px 40px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: none;
    background: #1F1F1F;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  
  .search-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 16px;
    top: 12px;
    svg path {
      fill: #fff;
    }
  }
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.yellow-text {
  color: #F0FF14 !important;
  fill: #F0FF14 !important;
  svg {
    path {
      fill: #F0FF14 !important;
    }
  }
}

.width-100 {
  width: 100% !important;
}