.explore-page-container {
  width: 100%;
  margin-top: 72px;
  padding: 40px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .title {
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-family: Arvo;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;    
  }
  
  .users-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px
  }
  
  .user {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    min-height: 152px;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #343434;
    background: linear-gradient(90deg, rgba(70, 0, 173, 0.00) 0%, rgba(70, 0, 173, 0.40) 100%);
    
    .profile-image {
      display: flex;
      width: 120px;
      height: 120px;
      justify-content: flex-end;
      align-items: center;
      border-radius: 999px;
      overflow: hidden;
      img.profile {
        width: 120px;
        height: 120px;
      }
    }

    h3 {
      align-self: stretch;
      color: #FFF;
      font-family: Arvo;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
    h4 {
      color: #FFF;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      align-self: stretch;
    }
   p {
      align-self: stretch;
      color: #B0B0B0;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    
    .info-container {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      flex: 1;
    }
    
    .stats-container {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;
    }
    
    .controls-container {
      display: flex;
      width: 150px;
      flex-direction: column;
      align-self: stretch;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 8px;
    }
  }
}