.footer-container {
    display: flex;
    padding: 120px 120px 40px 120px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    align-self: stretch;
    background: linear-gradient(180deg, rgba(70, 0, 173, 0.16) 0%, rgba(70, 0, 173, 0.00) 100%), #000;
    
    .footer {
        display: flex;
        align-items: flex-start;
        gap: 200px; 
        
        .footer-column-1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            
            .rigfish-description {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px; 
                width: 320px;
                
                .logo {
                    height: 32px;
                    img {
                        height: 32px;
                    }
                }
                p {
                    color: #898590;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;                    
                }
            }
            
            .social-icons {
                display: flex;
                align-items: flex-start;
                gap: 4px; 
                
                .social-icon {
                    padding: 12px;
                    border-radius: 999px;
                    background: #0E0B13;
                    svg {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }
        
        .footer-columns-234-grouping {
            display: flex;
            align-items: flex-start;
            gap: 100px;            
            .footer-column-2, .footer-column-3, .footer-column-4 {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;   
                width: 240px;
                
                h3 {
                    color: #FFF;
                    font-family: Arvo;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;                
                }
                a {
                    color: #898590;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 20px;                    
                }
            }
        }
    }
    
    .copyright-container {
         p {
            align-self: stretch;             
            color: #898590;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
         }
    }
}