.account-settings-page-container {
    margin: 72px auto 0px auto;
    width: 880px;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    
    h2.page-title {
        align-self: stretch;
        margin-bottom: 40px;
        color: #FFF;
        font-family: Arvo;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;        
    }
    
    .content-wrapper {
        display: flex;
        flex-direction: row;
        gap: 24px;
        
        .menu-container {
            width: 240px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            
            .menu-item {
                display: flex;
                padding: 8px 16px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                align-self: stretch; 
                border-radius: 999px;
                background: #081A37;
                color: #009EE7;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                text-transform: capitalize;
                
                &.active {
                    background-color: #009EE7;
                    color: #000000;
                }
            }
        }
        
        .content-container {
            flex: 1 0 0;
            padding: 40px;
            background: #000;            
            border: 1px solid;
            border-image: linear-gradient(321deg, rgba(255, 255, 255, 0.0576) 6.97%, rgba(255, 255, 255, 0.24) 35.47%, rgba(255, 255, 255, 0.0576) 64.06%, rgba(255, 255, 255, 0.096) 100%) 1 0;


        }
    }
}

