.modal-backdrop {
    display: none;
    
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(4px);

    &.modal-open {
        display: flex;
    }
    
    .modal-body {
        display: flex;
        flex: 0;
        align-items: flex-start;
        background-color: #000;
        color: #FFF;
        width: auto;
        max-height: 90vh;
        border-radius: 24px;
        position: relative;
        transition: none; 
        
        &.modal-body-center {
            transform: translateX(0);
        }
        
        &.modal-body-right {
            transform: translateX(calc(50vw + 100%));
        }

        &.modal-body-left {
            transform: translateX(calc(-50vw - 100%));
        }

        &.modal-body-bottom {
            transform: translateY(calc(50vh + 100%));
        }
        
        &.animate {
            transition: .5s;
        }

        .modal-close {
            display: flex;
            padding: 8px;
            align-items: flex-start;
            gap: 10px;
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 16px;
            border-radius: 999px;
            background: #1D152B;
            z-index: 1;
            width: 32px;
            height: 32px;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
        }
    }
}

.login-modal, .signup-modal {
    position: relative;
    display: flex;
    width: 960px;
    align-items: flex-start;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: #000;
    box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.40);

    .login-modal-image, .signup-modal-image {
        width: 421px;
        flex-shrink: 0;
        align-self: stretch;
        border-radius: 24px 0 0 24px;
    }

    .login-modal-image {
        background: linear-gradient(0deg, rgba(70, 0, 173, 0.24) 0%, rgba(70, 0, 173, 0.24) 100%), url('../images/login-modal-image.jpg') 50% / cover no-repeat, #FFF;
    }

    .signup-modal-image {
        background: linear-gradient(0deg, rgba(70, 0, 173, 0.24) 0%, rgba(70, 0, 173, 0.24) 100%), url('../images/signup-modal-image.jpg') 50% / cover no-repeat, #FFF;
    }
    
    .bolt {
        position: absolute;
        width: 64.865px;
        height: 80px;
        flex-shrink: 0;
        background: url('../images/bolt.png') 50% / cover no-repeat;
        top: 92px;
        left: -33px;
    }
    
    .login-modal-body, .signup-modal-body {
        display: flex;
        padding: 40px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        background: linear-gradient(131deg, rgba(70, 0, 173, 0.00) 0%, rgba(70, 0, 173, 0.16) 46.35%, rgba(70, 0, 173, 0.00) 100%);
        
        .login-modal-header, .signup-modal-header {
            display: flex;
            width: 459px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            h2 {
                align-self: stretch;
                color: #FFF;
                font-family: Arvo;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;                
            }
            p {
                align-self: stretch;
                color: #87858B;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;                
            }
        }
        
        hr {
            margin: 0;
            width: 40px;
            height: 4px;
            border-radius: 9px;
            background: #EBEBEB;
            opacity: 1;
        }
        
        .omniauth-buttons {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch; 
            
            .omniauth-facebook, .omniauth-google {
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;
                border-radius: 8px;
                background: #081A37;
                color: #FFF;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                .icon {
                    width: 16px;
                    height: 16px;
                    border-radius: 999px;
                }
            }

            .omniauth-facebook .icon {
                background: url('../images/facebook-icon.png') 50% / cover no-repeat;
            }

            .omniauth-google .icon {
                background: url('../images/google-icon.png') 50% / cover no-repeat;
            }

        }
        
        .signup-link, .login-link {
            color: #A6A3AB;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            
            strong {
                color: #009EE7;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;                
            }
        }
    }
}

.onboarding-modal {
    display: flex;
    width: 560px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(131deg, rgba(70, 0, 173, 0.00) 0%, rgba(70, 0, 173, 0.16) 46.35%, rgba(70, 0, 173, 0.00) 100%), #000;


    .onboarding-modal-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .back-button {
            width: 24px;
            height: 24px; 
            cursor: pointer;
        }

        h2 {
            color: #FFF;
            font-family: Arvo;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }
        
        p {
            color: #87858B;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }
    
    .continue {
        margin-top: 40px;
    }
    
    .skip {
        display: flex;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;        
        border-radius: 8px;
        color: #009EE7 !important;
        border: 1px solid #009EE7 !important;
        background: #000 !important;
        box-shadow: none !important;
        text-align: center;
        font-family: Poppins !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
    }
}


.welcome-modal, .toast-modal {
    display: flex;
    width: 600px;
    padding: 64px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;  
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(131deg, rgba(70, 0, 173, 0.00) 0%, rgba(70, 0, 173, 0.16) 46.35%, rgba(70, 0, 173, 0.00) 100%), #000;

    .icon-frame {
        position: relative;
        display: flex;
        padding: 24px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 999px;
        background: #081A37;
        .person-pin {
            width: 40px;
            height: 40px;
        }
        .check {
            display: flex;
            padding: 4px;
            align-items: flex-start;
            gap: 10px;
            position: absolute;
            right: 0px;
            bottom: 0px;
            border-radius: 999px;
            background: #00C667;
        }
    }
    
    .message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        h2 {
            align-self: stretch;
            color: #FFF;
            text-align: center;
            font-family: Arvo;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }
        p {
            align-self: stretch;
            color: #87858B;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;            
        }
    }
    
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        .complete {
            display: flex;
            padding: 8px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch; 
            border-radius: 8px;
            background: #F0FF14;
            box-shadow: -4px 4px 0px 0px #F5148E, 4px -4px 0px 0px #009EE7;
            color: #000;
            text-align: center;
            font-family: Arvo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; 
            cursor: pointer
            
        }
        .skip {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            flex: 1 0 0;
            color: #009EE7;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            cursor: pointer;
        }
    }
}

.favorites-modal {
    display: flex;
    width: 650px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;   
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(160deg, rgba(70, 0, 173, 0.00) 22.4%, rgba(70, 0, 173, 0.16) 42.36%, rgba(70, 0, 173, 0.00) 65.46%), #000;
    
    .modal-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .back-button {
            width: 24px;
            height: 24px; 
            cursor: pointer;
        }

        h2 {
            color: #FFF;
            font-family: Arvo;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }
        
        p {
            color: #87858B;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        margin-top: 16px;
        .complete {
            display: flex;
            padding: 8px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch; 
            border-radius: 8px;
            background: #F0FF14;
            box-shadow: -4px 4px 0px 0px #F5148E, 4px -4px 0px 0px #009EE7;
            color: #000;
            text-align: center;
            font-family: Arvo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; 
            cursor: pointer
            
        }
        .skip {
            display: flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            flex: 1 0 0;
            color: #009EE7;
            text-align: center;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            cursor: pointer;
        }
    }
}

.form-modal {
    position: relative;
    display: flex;
    width: 600px;
    align-items: flex-start;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: #000;
    box-shadow: 0px 16px 80px 0px rgba(0, 0, 0, 0.40);
    .form-modal-body, .modal-body {
        display: flex;
        padding: 40px;
        flex-direction: column;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        background: linear-gradient(131deg, rgba(70, 0, 173, 0.00) 0%, rgba(70, 0, 173, 0.16) 46.35%, rgba(70, 0, 173, 0.00) 100%);
        .form-modal-header, .modal-header {
            display: flex;
            position: sticky;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 8px;
            h2 {
                align-self: stretch;
                color: #FFF;
                font-family: Arvo;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;                
            }
            p {
                align-self: stretch;
                color: #87858B;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;                
            }
        }
        
        .vertical-form {
            overflow-y: auto;
            margin-right: -24px;
        }
        
        .modal-form-scroll {
            max-height: calc(100% - 56px);
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            overflow-y: scroll;
            margin-right: -24px;
            padding-right: 12px;
        }
        
        .submit-button {
            position: sticky;
            bottom: 0;
            margin-bottom: 4px;
            margin-left: 4px;
            width: calc(100% - 28px);
        }
    }
}

.gear-selection-modal {
    display: flex;
    width: 650px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;   
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(160deg, rgba(70, 0, 173, 0.00) 22.4%, rgba(70, 0, 173, 0.16) 42.36%, rgba(70, 0, 173, 0.00) 65.46%), #000;
    
    &.multiple {
        width: 1050px;
    }
    
    .modal-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .back-button {
            width: 24px;
            height: 24px; 
            cursor: pointer;
        }

        h2 {
            color: #FFF;
            font-family: Arvo;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }
        
        p {
            color: #87858B;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;
        align-self: stretch;
        margin-top: 16px;
        .select {
            display: flex;
            padding: 8px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            /* align-self: stretch; */ 
            border-radius: 8px;
            background: #F0FF14;
            box-shadow: -4px 4px 0px 0px #F5148E, 4px -4px 0px 0px #009EE7;
            color: #000;
            text-align: center;
            font-family: Arvo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; 
            cursor: pointer
            
        }
    }
}

.info-modal {
    display: flex;
    width: 680px;
    height: 600px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(160deg, rgba(70, 0, 173, 0.00) 22.4%, rgba(70, 0, 173, 0.16) 42.36%, rgba(70, 0, 173, 0.00) 65.46%), #000;

    .modal-body {
        background-color: transparent;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
        flex: 1;
    }
    
    .modal-header {
        height: 32px;
        overflow-y: hidden;
        h2 {
            align-self: stretch;
            color: #FFF;
            font-family: Arvo;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;                
        }
    }
    
    .column {
        overflow-y: auto;
        margin-right: -24px;
        flex: 1 0 0;
        height: 100%;
        width: calc(100% + 24px);
        padding-right: 14px;
    }
    
    .column-scroll {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        h4 {
            align-self: stretch;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 8px;
        }
        p {
            color: #B0B0B0;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;                
        }
        
        .row-group {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            .info-group {
                flex: 1;
            }
        }
        
        .photos-group {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            
            .photo {
                width: 150px;
                height: 150px;
                border-radius: 8px;
            }
            img {
                width: 150px;
                height: 150px;
                border-radius: 8px;
            }
        }
    }
    
    .signal-chain-window {
        width: 100%;
        overflow-x: auto;
        background: #141414;
        border-radius: 16px;
    }
    
    .signal-chain-window-scroll {
        padding: 20px 0px;
        height: 220px;
    }
    
    .signal-chain {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0px 34px;
        gap: 6px;
        .gear-item {
            display: flex;
            flex-direction: column;
            .image {
                background-color: #fff;
                border-radius: 16px 16px 0px 0px;
                height: 120px;
                width: 120px;
                padding: 10px;
                img {
                    height: 100px;
                    width: 100px;
                }
            }
            .name {
                height: 60px;
                width: 120px;
                padding: 10px;
                border-radius: 0px 0px 16px 16px;
                background-color: #141414;
                border: 1px solid rgba(255, 255, 255, 0.24);
                h4 {
                    color: #FFF;
                    text-align: center;
                    font-family: Arvo;
                    font-size: 7.919px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 11.879px;                    
                }
            }
        }
        div:last-of-type {
            padding-right: 28px;
        }
        .end-spacer {
            display: flex;
            width: 28px;
            height: 1px;
        }
    }
    
    .links-group {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
    }
    
    .reference-link {
        display: flex;
        flex-direction: row;
        gap: 8px;
        .title, .link {
            display: flex;
            flex-direction: column;
            gap: 4px;
            label {
                color: #A6A3AA;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;                
            }
            
            input {
                width: 100%;            
            }
        }
        
        .title {
            width: 160px;
        }
        
        .link {
            flex: 1;
        }
    }
    
    .images-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 8px;
        .image {
            height: 160px;
            border-radius: 8px;
            img {
                height: 160px;
                border-radius: 8px;
            }
        }
    }
    
    .audio-clip-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
        
        .audio-clip {
            display: flex;
            padding: 12px 16px;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            border-radius: 8px;
            border: 1px solid #251F2E;
            background: #100B19;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            
            .name {
                align-self: stretch;
                flex: 1 0 0;
            }
            
            svg.icon {
                width: 16px;
                height: 16px;
                cursor: default;
                path { 
                    fill: #ffffff 
                }
            }
        }
    }
}

.split-info-modal {
    display: flex;
    width: 1000px;
    height: 600px;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.24);
    background: linear-gradient(160deg, rgba(70, 0, 173, 0.00) 22.4%, rgba(70, 0, 173, 0.16) 42.36%, rgba(70, 0, 173, 0.00) 65.46%), #000;

    .modal-body {
        background-color: transparent;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
        flex: 1;
    }
    
    .modal-header {
        height: 32px;
        overflow-y: hidden;
        h2 {
            align-self: stretch;
            color: #FFF;
            font-family: Arvo;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;                
        }
    }
    
    .column-container {
        flex: 1 0 0;
        display: flex;
        flex-direction: row;
        gap: 40px;
        width: 100%;
        height: calc(100% - 48px);
        
        .left-column {
            width: 400px;
            .image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 400px;
                padding: 20px;
                background-color: #fff;
                border-radius: 20px;
                img {
                    width: 360px;
                    height: 360px;
                }
            }
        }
        
        .right-column {
            overflow-y: auto;
            margin-right: -24px;
            flex: 1 0 0;
            height: 100%;
        }
    }
}

.info-modal, .split-info-modal {
    .column-scroll, .right-column-scroll {
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        h4 {
            align-self: stretch;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 8px;
        }
        p {
            display: inline-flex;
            color: #B0B0B0;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;                
        }
        
        .row-group {
            display: flex;
            align-items: flex-start;
            gap: 24px;
            align-self: stretch;
            .info-group {
                flex: 1;
            }
        }
        
        .photos-group {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            
            .photo {
                width: 150px;
                height: 150px;
                border-radius: 8px;
            }
            img {
                width: 150px;
                height: 150px;
                border-radius: 8px;
            }
        }
    }
}