.black-container {
    background-color: #000;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-x: clip;
    align-self: stretch;
}

.bolt-accent-right {
    width: 134px;
    height: 167px;
    position: absolute;
    right: -40px;
    top: 80px;
    background: url('../images/bolt.png') 50% / cover no-repeat;
}

.homepage-container {
    width: 100%;
    position: relative;

    .hero {
        display: flex;
        height: 920px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 65.1%, #000 100%), linear-gradient(0deg, rgba(70, 0, 173, 0.40) 0%, rgba(70, 0, 173, 0.40) 100%), url('../images/hero-bg-scaled.png') 50% / cover no-repeat, #000;
    
        .hero-container {
            display: flex;
            padding: 200px 120px;
            justify-content: center;
            align-items: flex-end;
            gap: 10px;
            flex: 1 0 0;
            align-self: stretch;
            
            .hero-outer-frame {
                display: flex;
                width: 880px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 56px;

                .hero-inner-frame-1 {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    align-self: stretch;  
                    
                    h1 {
                        align-self: stretch;
                        color: #FFF;
                        text-align: center;
                        font-family: Arvo;
                        font-size: 72px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 80px;
                    }
                    
                    p {
                        align-self: stretch;
                        color: #FFF;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 40px;
                    }
                }
                .hero-inner-frame-2 {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;    
                    
                }
            }
        }
    }
    
    .homepage-2 {
        display: flex;
        position: relative;
        padding: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        align-self: stretch;
        background: #000;
        
        .header-with-sub {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            align-self: stretch;            
            
            h2 {
                align-self: stretch;
                color: #FFF;
                text-align: center;
                font-family: Arvo;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 56px;
            }
            
            p {
                align-self: stretch;
                color: #A3A3A3;
                text-align: center;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px;                
            }
        }
        
        .signal-chain-image {
            display: flex;
            position: relative;
            width: 1120px;
            height: 640px;
            padding: 560px 0px 40px 0px;
            justify-content: center;
            align-items: center;        
            border-radius: 40px;
            border-image: linear-gradient(0deg, #FFFFFF14 50%, #FFFFFF00 100%);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 46.79%, #000 100%), url('../images/signal-chain-image.png') 50% / cover no-repeat;

            .bolt {
                width: 90px;
                height: 111px;
                position: absolute;
                left: -45px;
                top: 265px;
                background: url('../images/bolt.png') 50% / cover no-repeat;
            }
        }
    }
    
    .homepage-3 {
        max-width: 1680px;
        display: flex;
        padding: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 80px;
        align-self: stretch;
        background: #000;
        
        h2 {
            align-self: stretch;
            color: #FFF;
            text-align: center;
            font-family: Arvo;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 56px;            
        }
        
        .two-column {
            display: flex;
            align-items: flex-start;
            gap: 80px;
            align-self: stretch;

            .large-text {
                flex: 1 0 0;
                color: #FFF;
                font-family: Arvo;
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: 56px;
            }
            
            .muted {
                flex: 1 0 0;
                color: #A3A3A3;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px;
            }
        }
        
        .three-column {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;
            
            .icon-box {
                display: flex;
                padding: 40px 32px;
                flex-direction: column;
                align-items: flex-start;
                gap: 24px;
                flex: 1 0 0;
                border-radius: 24px;
                border-style: solid;
                border-width: 1px;
                border-image: linear-gradient(180deg, rgba(240, 255, 20, 0) 5.73%, #F0FF14 26.04%, rgba(240, 255, 20, 0) 45.31%) 1;
                /*border-image: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08));*/
                background: rgba(255, 255, 255, 0.04);
                
                .icon {
                    display: flex;
                    padding: 8px;
                    align-items: flex-start;
                    gap: 10px;
                    border-radius: 16px;
                    background: #F0FF14;
                    box-shadow: -4px 4px 0px 0px #F5148E, 4px -4px 0px 0px #009EE7;
                    width: 56px;
                    height: 56px;
                    
                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
                
                .icon-box-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;

                    h4 {
                        align-self: stretch; 
                        color: #FFF;
                        font-family: Arvo;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 32px;
                    }
                    p {
                        align-self: stretch; 
                        color: #FFF;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    
    .homepage-4 {
        display: flex;
        padding: 120px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 80px;
        align-self: stretch;        
        background: linear-gradient(180deg, rgba(240, 255, 20, 0.00) 0%, rgba(240, 255, 20, 0.08) 48.44%, rgba(240, 255, 20, 0.00) 100%), #000;
        
        .testimonial {
            display: flex;
            width: 800px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 40px;
            
            .quote-icon {
                width: 94.316px;
                height: 64px;
                fill: #F0FF14;
                /*filter: drop-shadow(-4px 4px 0px #F5148E) drop-shadow(4px -4px 0px #009EE7);*/
            }
            
            .testimonial-text {
                align-self: stretch;
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: 40px; /* 125% */
            }
            
            .testimonial-author {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 4px;
                .testimonial-name {
                    color: #A3A3A3;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;                    
                }
                .testimonial-title {
                    color: #A3A3A3;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;                    
                }
            }
            
            .testimonial-paging {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;                
                .testimonial-page {
                    width: 10px;
                    height: 10px;
                    border: 5px solid #FFF;
                    border-radius: 10px;
                    background-color: #FFF;
                    opacity: 0.24;
                    
                    &.active {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
