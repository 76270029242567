.signal-chain-builder {
    margin-top: 72px;
    height: calc(100vh - 72px);
    width: 100%;
    position: relative;
    
    .title {
        position: absolute;
        width: 100%;
        padding: 10px 0px;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
            color: #FFF;
            font-family: Arvo;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
        }
    }
    .canvas-outer-container {
        position: relative;
        display: flex;
        overflow-y: auto;
        width: 100%;
        height: 100%;
        padding: 0px 120px;
        .canvas-inner-container {
            display: flex;
            .canvas {
                left: 0px;
                height: 100%;
                min-width: calc(100vw - 240px);
                padding: 120px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;
                
                .gear-item {
                    position: relative;
                    .image {
                        width: 280px;
                        height: 280px;
                        padding: 20px;
                        background-color: #ffffff;
                        border-radius: 24px 24px 0px 0px;
                        img {
                            width: 240px;
                            height: 240px;
                        }
                    }
                    
                    .info {
                        position: relative;
                        width: 280px;
                        height: 180px;
                        min-height: 160px;
                        background-color: #141414;
                        border-radius: 0px 0px 24px 24px;
                        display: flex;
                        padding: 16px 16px 32px 16px;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: left;
                        gap: 8px;
                        align-self: stretch;                    
    
                        .name {
                            align-self: stretch;
                            color: #FFF;
                            text-align: center;
                            font-family: Arvo;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 24px;
                        }
                        .stats {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            color: #898989;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px; /* 114.286% */
                            .user-count, .signal-chain-count {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                align-self: stretch;                                
                            }
                        }
                        .select-gear-button-container {
                            position: absolute;
                            bottom: -16px;
                            left: 0px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 280px;
                            .select-gear-button {
                                display: flex;
                                padding: 8px 16px;
                                justify-content: center;
                                align-items: center;
                                gap: 4px;
                                border-radius: 24px;
                                background: #009EE7;
                                color: #FFF;
                                font-family: Poppins;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                                cursor: pointer;
                            }
                        }
                    }
                    
                    .control-overlay {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 280px;
                        height: 280px;
                        background: rgba(0, 0, 0, 0.4);
                        display: none;
                        .remove {
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            padding: 8px;
                            height: 40px;
                            width: 40px;
                            border-radius: 999px;
                            background-color: #000;
                            opacity: 0.6;
                            cursor: pointer;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                    
                    &:hover {
                        .control-overlay {
                            display: flex;
                        }                    
                    }
                }
                
                .connection {
                    position: relative;
                    width: 82px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .add-button {
                        position: absolute;
                        left: 21px;
                        display: inline-flex;
                        padding: 8px;
                        height: 40px;
                        width: 40px;
                        align-items: flex-start;
                        gap: 10px;
                        border-radius: 999px;
                        background: #141414;
                        backdrop-filter: blur(20px);
                        cursor: auto;
                        
                        .add-buttons {
                            position: absolute;
                            display: none;
                            top: -40px;
                            left: -50px;
                            height: 120px;
                            width: 140px;
                            justify-content: space-between;
                            align-items: center;
                            flex-direction: column;
                            background: transparent;
                        }
                        
                        .add-multiple-pedals, .add-multiple-amps {
                            display: flex;
                            padding: 8px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 4px;
                            border-radius: 24px;
                            background: #009EE7;
                            color: #FFF;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            cursor: pointer;
                        }
                        
                        .add-single-pedal {
                            padding: 8px;
                            height: 40px;
                            width: 40px;
                            align-items: flex-start;
                            gap: 10px;
                            border-radius: 999px;
                            background: #141414;
                            backdrop-filter: blur(20px);
                            cursor: auto;
                        }
                        
                        &:hover .add-buttons {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
    
    .save-button {
        width: 200px;
        position: absolute;
        bottom: 50px;
        right: 50px;
    }
}