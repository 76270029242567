
*, :before, :after {
    font-family: 'Inter';
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;    
}

head {
    display: none;
}

body {
    padding: 0;
    margin: 0;
    background-color: #000;
    color: #FFF;
}

h1, h2, h3, h4, h5, h6, p, a, span {
    font-family: 'Inter';
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

strong {
    font-weight: 700;
}

a {
    text-decoration: none;
    cursor: pointer;
}

ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

.text-center {
    text-align: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: stretch;
    align-self: stretch;
}

.btn {
    padding: 10px 24px 10px 24px;
    border-radius: 4px;
    font-weight: 700;
    font-family: 'Inter';
    font-size: 11px;
    line-height: 16px;
    cursor: pointer;
}

.btn-primary {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #F0FF14;
    box-shadow: -4px 4px 0px 0px #F5148E, 4px -4px 0px 0px #009EE7;
    color: #000;
    font-family: Arvo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.btn-secondary {
    display: flex;
    padding: 8px 24px;
    align-items: flex-start;
    gap: 10px;                        
    border-radius: 8px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(20px);
    color: #FFF;
    font-family: Arvo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
}

.error {
    color: #FF0000;
    font-weight: 700;
}

.page-content {
    margin: 0px 0px 0px 0px;
    padding: 0;
    width: 100%;
    display: flex;
}

::-webkit-scrollbar /* the scrollbar. */ {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-button /* the buttons on the scrollbar (arrows pointing upwards and downwards). */ {
        
}
::-webkit-scrollbar-thumb /* the draggable scrolling handle. */ {
    width: 6px;
    height: 6px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.24);
}
::-webkit-scrollbar-track /* the track (progress bar) of the scrollbar. */ {
    
}
::-webkit-scrollbar-track-piece /* the track (progress bar) NOT covered by the handle. */ {
    
}
::-webkit-scrollbar-corner {
    
}



