.profile-page-container {
  width: 100%;
  background: #000;
  .profile-bio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .backdrop {
      display: flex;
      width: 100%;
      height: 312px;
      /* padding: 16px 16px 176px 1616px; */
      justify-content: flex-end;
      align-items: center;
      background: url('../images/profile-page-background.jpg') 50% / cover no-repeat, #141414;
    }
    .profile-bio {
      display: flex;
      width: 100%;
      padding: 0px 120px;
      align-items: flex-start;
      gap: 40px;  
      
      .profile-name-image-main-container {
        position: relative;
        top: 0px;
        margin-top: -160px;
        display: flex;
        width: 320px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        align-self: stretch; 
        .profile-name-image-container {
          display: flex;
          width: 320px;
          padding: 40px 24px;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          flex-shrink: 0;          
          border-radius: 16px;
          border: 1px solid rgba(255, 255, 255, 0.24);
          background: linear-gradient(160deg, rgba(70, 0, 173, 0.00) 22.4%, rgba(70, 0, 173, 0.16) 42.36%, rgba(70, 0, 173, 0.00) 65.46%), #000;
          .profile-name-image {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            align-self: stretch; 
            .profile-image {
              display: flex;
              width: 160px;
              height: 160px;
              justify-content: flex-end;
              align-items: center;
              border-radius: 999px;
              overflow: hidden;
              img.profile {
                width: 160px;
                height: 160px;
              }
            }
            .upload-icon {
              position: absolute;
              right: 56px;
              top: 112px;
              display: flex;
              padding: 12px;
              justify-content: center;
              align-items: center;
              gap: 10px;   
              border-radius: 999px;
              background: #009EE7;
            }
            .name-and-details {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
              align-self: stretch; 
              h3 {
                align-self: stretch;
                color: #FFF;
                text-align: center;
                font-family: Arvo;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
              }
              p {
                align-self: stretch;
                color: #B0B0B0;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
              }
            }
          }
          .line {
            width: 272px;
            height: 1px;
            background: #141414;
          }
          .followings-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            align-self: stretch;            
            .following, .followers {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 4px;
              flex: 1 0 0;
              h3 {
                align-self: stretch;
                color: #FFF;
                text-align: center;
                font-family: Arvo;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
              }
              p {
                align-self: stretch;
                color: #B0B0B0;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;              
              }
            }
          }
        }
      }
      
      .profile-details-container {
        display: flex;
        padding: 24px 0px;
        align-items: flex-start;
        gap: 240px;
        flex: 1 0 0;
        .profile-details {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          flex: 1 0 0;  
          .bio, .genres, .daws, .favorite {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;            
            h4 {
              color: #FFF;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              align-self: stretch;
            }
            p {
              color: #B0B0B0;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;              
              align-self: stretch;
            }
          }
          .settings-button {
            position: absolute;
            top: 0px;
            right: 0px;
          }
        }
      }
    }
  }
  .profile-content-container {
    padding: 80px 120px;
    .profile-menu {
      margin-bottom: 40px;
    }
    .profile-header-bar {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      
      .profile-header {
        display: flex;
        gap: 8px;
        align-items: flex-start;
        h2 {
          color: #FFF;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
        .count-badge {
          display: flex;
          padding: 6px 8px;
          min-width: 28px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 999px;
          background: #F0FF14;
          color: #000;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
    
    .profile-content.empty {
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.24);
      background: linear-gradient(160deg, rgba(70, 0, 173, 0.00) 22.4%, rgba(70, 0, 173, 0.16) 42.36%, rgba(70, 0, 173, 0.00) 65.46%), #000;      
    }
    
    .empty-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;
      padding: 80px;
      
      .icon {
        display: flex;
        padding: 24px;
        align-items: flex-start;
        gap: 10px;        
        border-radius: 999px;
        background: #081A37;
        svg {
          height: 40px;
          width: 40px;
          path {
            fill: #009EE7;
          }
        }
      }
      h4 {
        align-self: stretch;
        color: #FFF;
        text-align: center;
        font-family: Arvo;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 16px;
      }
      p {
        align-self: stretch;
        color: #87858B;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
    
    .signal-chains {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 40px;
      
      .signal-chain {
        width: 320px;
        .image-panel {
          position: relative;
          width: 320px;
          height: 320px;
          border-radius: 24px;
          border: 1px solid rgba(255, 255, 255, 0.24);
          background: linear-gradient(160deg, rgba(70, 0, 173, 0.00) 22.4%, rgba(70, 0, 173, 0.16) 42.36%, rgba(70, 0, 173, 0.00) 65.46%), #000;      
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          gap: 20px;
          padding: 10px 10px;
          z-index: 0;

          .overlay {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 320px;
            height: 320px;
            border-radius: 24px;
            z-index: 1;
            background: rgba(0, 0, 0, .24);
            
            &.open {
              display: flex;
            }
            
            .menu-button {
              position: absolute;
              top: 8px;
              right: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              background-color: #000;
              border-radius: 999px;
              border: 1px solid rgba(255, 255, 255, 0.24);
              z-index: 2;
              cursor: pointer;
              
              svg {
                width: 24px;
                height: 24px;
              }
            }
            
            .signal-chain-menu {
              position: absolute;
              top: 56px;
              right: 8px;
              display: none;
              flex-direction: column;
              gap: 8px;
              width: 200px;
              padding: 16px;
              border-radius: 8px;
              border: 1px solid #272727;
              background: #1A1A1A;              

              &.open {
                display: flex;
              }
              
              a {
                flex: 1 0 0;
                display: flex;
                gap: 8px;
                
                svg  {
                  width: 16px;
                  height: 16px;
                  path {
                    fill: #87858B;
                  }
                }
                span {
                  color: #87858B;
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }
                
                &:hover {
                  svg path {
                    fill: #fff
                  }
                  span {
                    color: #fff;
                  }
                }
              }
            }
          }
          
          &:hover .overlay {
            display: flex;
          }
        }
        .image {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 320px;
          height: 320px;
          border-radius: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          gap: 20px;
          padding: 10px 10px;
          .gear-image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            border-radius: 10px;
            padding: 7px;
            background: #fff;
            color: #000;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            
            img {
              width: 56px;
              height: 56px;
            }
            .blank-image {
              width: 56px;
              height: 56px;
              background: #fff;
            }
          }
          .lines {
            position: absolute;
            top: 0px;
            left: 0px;
            .small-horizontal-line {
              position: absolute;
              width: 20px;
              height: 2px;
              background-color: #B0B0B0;
            }
            .large-horizontal-line-1 {
              position: absolute;
              width: 93px;
              height: 2px;
              background-color: #B0B0B0;
            }
            .large-horizontal-line-2 {
              position: absolute;
              width: 137px;
              height: 2px;
              background-color: #B0B0B0;
            }
            .large-horizontal-line-3 {
              position: absolute;
              width: 182px;
              height: 2px;
              background-color: #B0B0B0;
            }
            .tiny-vertical-line {
              position: absolute;
              width: 2px;
              height: 10px;
              background-color: #B0B0B0;
            }
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: flex-start;
          
          h3 {
            align-self: stretch;
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;          
          }
          
          p {
            align-self: stretch;
            color: #B0B0B0;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;            
          }
        }
      }
    }
    
    .gear-items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 40px;
      
      .gear-item {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
        justify-content: flex-start;
        width: 320px;
        
        .image-panel {
          position: relative;
          display: flex;
          height: 320px;
          width: 320px;
          padding: 20px;
          justify-content: center;
          align-items: center;
          align-self: stretch; 
          border-radius: 24px;
          background: #FFF;
          z-index: 0;
          img {
            height: 280px;
            width: 280px;
          }
          
          .overlay {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 320px;
            height: 320px;
            border-radius: 24px;
            z-index: 1;
            background: rgba(0, 0, 0, .24);
            
            &.open {
              display: flex;
            }
            
            .menu-button {
              position: absolute;
              top: 8px;
              right: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              background-color: #000;
              border-radius: 999px;
              z-index: 2;
              cursor: pointer;
              
              svg {
                width: 24px;
                height: 24px;
              }
            }
            
            .gear-item-menu {
              position: absolute;
              top: 56px;
              right: 8px;
              display: none;
              flex-direction: column;
              gap: 8px;
              width: 200px;
              padding: 16px;
              border-radius: 8px;
              border: 1px solid #272727;
              background: #1A1A1A;              

              &.open {
                display: flex;
              }
              
              a {
                flex: 1 0 0;
                display: flex;
                gap: 8px;
                
                svg  {
                  width: 16px;
                  height: 16px;
                  path {
                    fill: #87858B;
                  }
                }
                span {
                  color: #87858B;
                  font-family: Inter;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                }
                
                &:hover {
                  svg path {
                    fill: #fff
                  }
                  span {
                    color: #fff;
                  }
                }
              }
            }
          }
          
          &:hover .overlay {
            display: flex;
          }
        }
        .name {
          align-self: stretch;
          color: #FFF;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
    
    .users {
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 16px;
      z-index: 0;
      .user {
        position: relative;
        width: 500px;
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
        background: #141414;
        z-index: 0;
        .profile-image {
          flex: 0;
          width: 56px;
          height: 56px;
          border-radius: 999px;
          cursor: pointer;
          img {
            width: 56px;
            height: 56px;
            border-radius: 999px;
          }
        }
        .info {
          flex: 1 0 0;
          cursor: pointer;
          h4 {
            align-self: stretch;
            color: #FFF;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;   
            margin-bottom: 8px;
          }
          p {
            align-self: stretch;
            color: #B0B0B0;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;            
          }
        }
        .controls {
          flex: 0;
          display: flex;
        }
        
        .menu-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background-color: #000;
          border-radius: 999px;
          cursor: pointer;
        
          svg {
            width: 24px;
            height: 24px;
            transform: scale(.8);
          }
        }

        .user-menu {
          position: absolute;
          top: 68px;
          right: 16px;
          display: none;
          flex-direction: column;
          gap: 8px;
          width: 160px;
          padding: 16px;
          border-radius: 8px;
          border: 1px solid #272727;
          background: #1A1A1A;              

          &.open {
            display: flex;
          }
          
          a {
            flex: 1 0 0;
            display: flex;
            gap: 8px;
            
            svg  {
              width: 16px;
              height: 16px;
              path {
                fill: #87858B;
              }
            }
            span {
              color: #87858B;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
            
            &:hover {
              svg path {
                fill: #fff
              }
              span {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}