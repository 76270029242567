
.base-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    padding: 16px 120px 16px 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    backdrop-filter: blur(20px);
    z-index: 1;
    
    .logo {
        height: 40px;
        width: 205.647px;
        img {
            height: 40px;
        }
    }
    
    .base-navigation {
        ul {
            display: flex;
            flex-direction: row;
            height: 16px;
            gap: 64px;
            list-style-type: none;
            li {
                display: flex;
                a {
                    font-family: Poppins;
                    font-size: 14px;
                    line-height: 16px;
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.73);
                    &.active {
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    
    .login-navigation {
        ul {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            height: 36px;
            width: 176px;
            gap: 8px;
            list-style-type: none;
            li {
                display: block;               
                
            }
        }
    }

    .main-navigation {
        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 40px;
            gap: 8px;
            list-style-type: none;
            li a, li div {
                display: flex;
                padding: 12px 20px 12px 20px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                background-color: #1F1F1F;
                font-family: Poppins;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-decoration: none;
                color: #FFFFFF;
                cursor: pointer;
                
                span {
                    width: 16px;
                    height: 16px;
                    svg path {
                        fill: #FFF;
                    }
                }
                
                &.active {
                    background-color: #F0FF14;
                    color: #000000;
                    span svg path { fill: #000; }
                }
            }
        }
    }

    .account-navigation {
        position: relative;
        display: flex;
        align-items: center;
        gap: 16px;
        .notifications {
            position: relative;
            display: flex;
            padding: 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 99px;
            border: 1px solid #303030;
            background: #141414;
            .badge {
                display: none;
                position: absolute;
                top: 0px;
                right: 0px;
                height: 8px;
                width: 8px;
                padding: 0;
                margin: 0;
                border-radius: 8px;
                background: #F5148E;
                &.active {
                    display: block;
                }
            }
        }
        .account-button {
            position: relative;
            height: 36px;
            border-radius: 36px;
            background-color: transparent;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            padding-right: 8px;
            .profile-image {
                height: 36px;
                width: 36px;
                border-radius: 36px;
                overflow: hidden;
                img {
                    height: 36px;
                    width: 36px;
                }
            }
            .username {
                height: 16px;
                color: #AAA;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;            
            }
            .toggle {
                margin-right: 8px;
            }
        }
        
        .account-menu {
            display: none;
            position: absolute;
            right: 16px;
            top: 46px;
            width: 180px;
            padding: 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;            
            border-radius: 8px;
            border: 1px solid #251F2E;
            background: #100B19;
            &.visible {
                display: flex;
            }
            a {
                display: flex;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                span {
                    flex: 1 0 0;
                    color: #87858B;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;                
                }
                svg path {
                    fill: #87858B;
                }
                
                &:hover {
                    span {
                        color: #fff;
                    }
                    svg path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}
