
input[type='text'], input[type='password'], input[type='email'], input[type='number'] {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #2F2A39;
    background: #080113;
    color: #87858B;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    &:active, &:focus, &:focus-visible {
        border: 1px solid #ffffff;
        box-shadow: none;
        outline: none;
    }
}


.vertical-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    .form-group {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        flex: 1;
        
        label {
            align-self: stretch;
            color: #FFF;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
        
        input[type='text'], input[type='password'], input[type='email'], input[type='number'] {
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #2F2A39;
            background: #080113;
            color: #87858B;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;

            &:active, &:focus, &:focus-visible {
                border: 1px solid #ffffff;
                box-shadow: none;
                outline: none;
            }
        }
        input[type='submit'] {
            display: flex;
            padding: 8px 24px;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            border-radius: 8px;
            background: #F0FF14;
            border: none;
            box-shadow: -4px 4px 0px 0px #F5148E, 4px -4px 0px 0px #009EE7;
            color: #000;
            text-align: center;
            font-family: Arvo;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            justify-content: center;

            &.skip {
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                flex: 1 0 0;
                color: #009EE7;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px;
                cursor: pointer;
                background: transparent;
                box-shadow: none;
            }
        }
        
        textarea {
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 8px;
            min-height: 200px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid #2F2A39;
            background: #080113;
            color: #87858B;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 114.286% */
            &:active, &:focus, &:focus-visible {
                border: 1px solid #ffffff;
                box-shadow: none;
                outline: none;
            }
        }
        
        .password-visibility-icon {
            position: absolute;
            top: 34px;
            right: 12px;  
            cursor: pointer;
            color: #FFF;
        }
        
        span.error {
            position: absolute;
            top: 70px;
            left: 16px;
            background-color: #E4E4E4;
            border-radius: 8px;
            color: #000000;
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            vertical-align: middle;
            padding: 8px 16px;
            border: 2px solid #000000;
            z-index: 2;
            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                left: 8px;
                top: -24px;
                border: 8px solid transparent;
                border-bottom: 16px solid #000000;
                z-index: 3;                
            }
            &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                left: 10px;
                top: -18px;
                border: 6px solid transparent;
                border-bottom: 12px solid #E4E4E4;
                z-index: 4;                
            }
        }
        
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active,
        input:-internal-autofill-selected,
        input:-internal-autofill-previewed {
            -webkit-background-clip: text;
            -webkit-text-fill-color: #87858B;
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px #23232329;
            color: #87858B;
        }
        
        .radio-select {
            display: flex;
            flex-direction: row;
            gap: 8px;
            justify-content: space-evenly;
            align-items: flex-start;
            align-self: stretch;
            label {
                position: relative;
                display: flex;
                padding: 12px 16px;
                align-items: center;
                gap: 8px;
                flex: 1 0 0;                
                border-radius: 999px;
                border: 1px solid #251F2E;
                background: #100B19;
                color: #87858B;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                
                input {
                    display: none;
                }
                
                svg {
                    path {
                        fill: #87858B;
                    }
                }
                
                &::after {
                    position: absolute;
                    content: '';
                    top: 12px;
                    right: 12px;
                    height: 16px;
                    width: 16px;
                    display: flex;
                    padding: 4px;
                    align-items: center;
                    border-radius: 999px;
                    border: 4px solid #36323E;
                }
                &.checked {
                    color: #fff;
                    border-color: #009EE7;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                    &::after {
                        border-color: #009EE7;
                    }
                }
            }
        }
        
        .select-input {
            display: none;
        }
        
        .select-field {
            width: 100%;
            position: relative;

            .select-text-input {
                position: relative;
                display: flex;
                padding: 12px 16px;
                height: 40px;
                width: 100%;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid #2F2A39;
                background: #080113;
                color: #87858B;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                cursor: default;
                
                .caret {
                    position: absolute;
                    top: 12px;
                    right: 16px;
                    height: 16px;
                    width: 16px;
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
                
                &.active {
                    border: 1px solid #ffffff;
                }
            }
            
            .select-menu {
                display: none;
                width: 100%;
                max-height: 140px;
                padding: 0px 0px;
                flex-direction: column;
                align-items: flex-start;
                position: absolute;
                left: 0px;
                top: 40px;
                border-radius: 8px;
                border: 1px solid #251F2E;
                background: #000;
                box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
                z-index: 1;
                cursor: auto;
                overflow-y: scroll;
                
                &.open {
                    display: flex;
                }
                
                .select-menu-item {
                    align-self: stretch;
                    cursor: default;
                    padding: 6px 16px;
                    &:hover, &.selected {
                        background-color: #081A37;
                    }
                }
            }
        }
        
        &.datepicker {
            gap: 0px;
            label {
                margin-bottom: 8px;
            }
        }
        
        .react-datepicker-wrapper {
            width: 100%;
            input {
                width: 100%;
            }
        }
        .react-datepicker__triangle {
            transform: translate(120px, 0px) !important;
        }

        .multi-image-upload {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 8px;
            
            .photo {
                display: flex;
                position: relative;
                width: 160px;
                height: 160px;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                
                img {
                    height: 160px;
                    width: 160px;
                    border-radius: 8px;
                }
                
                .remove {
                    position: absolute;
                    display: flex;
                    padding: 8px;
                    height: 32px;
                    width: 32px;
                    top: 8px;
                    right: 8px;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                    border-radius: 999px;
                    background: #231F2B;
                    cursor: pointer;
                    svg {
                        path {
                            fill: #87858B;
                        }
                    }
                }
            }
            
            .photo-dropzone {
                display: flex;
                position: relative;
                width: 160px;
                height: 160px;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                border: 1px solid #251F2E;
                background: #100B19;
                color: #009EE7;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;   
                cursor: pointer;
            }
        }
        
        .multi-audio-clip-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 12px;
            
            .controls {
                display: flex;
                gap: 24px;
                justify-content: space-between;
                width: 200px;
                
                .control {
                    display: flex;
                    gap: 8px;
                    color: #009EE7;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    cursor: default;
                    svg {
                        width: 16px;
                        height: 16px;
                        path {
                            fill: #009EE7 !important;
                        }
                    }
                }
            }
        }
        
        .multi-audio-clip-upload {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 12px;
            
            .audio-clip {
                display: flex;
                padding: 12px 16px;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                align-self: stretch;
                width: 100%;
                border-radius: 8px;
                border: 1px solid #251F2E;
                background: #100B19;
                color: #FFF;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                
                .name {
                    align-self: stretch;
                    flex: 1 0 0;
                }
                
                svg.icon {
                    width: 16px;
                    height: 16px;
                    cursor: default;
                    path { 
                        fill: #ffffff 
                    }
                }
            }
        }
        
        .field-array-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 12px;
            
            .add-button {
                display: flex;
                gap: 8px;
                color: #009EE7;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                cursor: pointer;
            }
        }
        
        .links-field-array {
            display: flex;
            flex-direction: column;
            gap: 12px;
            width: 100%;
            
            .link-field {
                width: 100%;
                display: flex;
                gap: 8px;
                
                .subfield {
                    position: relative;
                    label {
                        color: #A6A3AA;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;                     
                    }
                    input {
                        display: flex;
                        padding: 12px 16px;
                        align-items: center;
                        gap: 8px;
                        align-self: stretch;
                        border-radius: 8px;
                        border: 1px solid #251F2E;
                        background: #100B19;
                        color: #A6A3AA;
                        width: 100%;
                        &:active, &:focus, &:focus-visible {
                            border: 1px solid #ffffff;
                            box-shadow: none;
                            outline: none;
                        }
                    }
                    .icon {
                        position: absolute;
                        top: 36px;
                        right: 16px;
                        width: 16px;
                        height: 16px;
                        path: { fill: #FFFFFF; }
                    }
                }
                
                .title {
                    width: 150px;
                }
                
                .link {
                    flex: 1 0 0;
                }
            }
        }
    }
}

.error {
    color: #F5148E !important;
}

.info-tooltip {
    height: 16px;
    width: 16px;
    margin-left: 8px;
    position: relative;
    display: inline-block;
    
    .tooltip {
        position: absolute;
        top: 20px;
        left: -112px;
        width: 240px;
        z-index: 1000;
        display: none; 
        padding: 8px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 8px;
        background: #202020;
        color: #BAB7BF;
        font-family: Poppins;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        opacity: 1;

        &.open {
            display: flex;
        } 
        
        &::before {
            content: '';
            position: absolute;
            top: -7px;
            width: 16px;
            height: 8px;
            fill: #202020;
            background-image: url('../images/caret-up.svg');
        }
    }
}

.radio-panel-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;    
    
    .panel-button {
        //flex: 1 0 162px;
        label {
            position: relative;
            display: flex;
            padding: 7px 8.333px 8px 8.334px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            flex: 1 0 0;
            border-radius: 16px;
            border: 1px solid #081531;
            background: #081531;
            
            .icon {
                width: 136px;
                height: 116px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    path {
                        fill: #FFFFFF;
                    }
                }
            }
            span {
                color: #FFF;
                text-align: center;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                width: 100%;
            }
            .check {
                display: none;
                position: absolute;
                top: 12px;
                right: 12px;
                padding: 4px;
                width: 24px;
                height: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;                
                border-radius: 999px;
                background: #009EE7;
                fill: #FFF;
                svg path {
                    fill: #FFF;
                }
            }

            &.checked {
                border: 1px solid #009EE7;
                background: #000;
                box-shadow: 2px -2px 0px 0px #009EE7;
                
                .check {
                    display: inline-flex;
                }
            }
            
        }
    }
}

.ajax-multi-select {
    
    .search-container {
        position: relative;
        width: 100%;
        display: flex;

        .search {
            padding-left: 40px !important;
            flex: 1;
        }
    
        .search-icon {
            position: absolute;
            top: 12px;
            left: 16px;
            width: 16px;
            height: 16px;
            fill: #87858B;
            color: #87858B;
        }
    }
    
    .options {
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        gap: 8px 4px;
        align-self: stretch;
        flex-wrap: wrap;
        // max-height: 100px;
        // overflow-y: auto;

        span {
            display: flex;
            padding: 8px 12px;
            align-items: center;
            gap: 4px;            
            border-radius: 999px;
            background: #081A37;
            color: #009EE7;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: capitalize;
            cursor: pointer;
            
            &.selected {
                background: #009EE7;    
                color: #000;
            }
            
            &.no-results {
                background: transparent;
            }
        }
    }
}

.gear-selection-panel {
    
    display: flex;
    
    .selection-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        width: 568px;
        .categories {
            display: flex;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
            flex-wrap: wrap;
        }
    
        .search-container {
            position: relative;
            width: 100%;
            display: flex;
    
            .search {
                padding-left: 40px !important;
                flex: 1;
                display: flex;
                padding: 12px 16px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                border: 1px solid #2F2A39;
                background: #080113;
                color: #87858B;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 114.286% */
            }
        
            .search-icon {
                position: absolute;
                top: 12px;
                left: 16px;
                width: 16px;
                height: 16px;
                fill: #87858B;
                color: #87858B;
            }
        }
        
        .filters-container {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            .filters-button {
                display: flex;
                position: relative;
                height: 32px;
                padding: 12px 10px 12px 16px;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid #251F2E;
                background: #120B1D;
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                cursor: pointer;
                
                .filter-dropdown {
                    display: none;
                    width: 320px;
                    max-height: 200px;
                    padding: 16px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    position: absolute;
                    left: 0px;
                    top: 40px;
                    border-radius: 8px;
                    border: 1px solid #251F2E;
                    background: #000;
                    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
                    z-index: 1;
                    cursor: auto;
    
                    h6 {
                        color: #FFF;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 24px;                    
                    }
                    .options {
                        overflow-y: auto;
                        align-self: stretch;
                        margin-right: -13px;
                        .option {
                            cursor: pointer;
                            width: 286px;
                            padding: 4px 0px;
                            &.active, &:hover {
                                background-color: #081A37;
                            }
                        }
                    }                
                }
                
                &.active .filter-dropdown {
                    display: flex;
                }
            }
        }
        
        .results {
            display: flex;
            gap: 16px;
            height: 240px;
            flex-wrap: wrap;
            overflow-y: auto;
            margin-right: -24px;
            align-self: stretch;
            
            .gear-card {
                width: 130px;
                display: flex;
                flex-direction: column;
                gap: 8px;
    
                .img-card {
                    position: relative;
                    width: 130px;
                    height: 130px;
                    padding: 10px;
                    background-color: #ffffff;
                    border-radius: 16px;
                    img {
                        height: 110px;
                        width: 110px;
                    }
                    .item-selected {
                        position: absolute;
                        display: flex;
                        top: 5px;
                        right: 5px;
                        background-color: #009EE7;
                        width: 24px;
                        height: 24px;
                        padding: 4px;
                        border-radius: 999px;
                    }
                }            
                
                p {
                    align-self: stretch;
                    color: #FFF;
                    font-family: Poppins;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                }
            }
        }
    }
    .gear-selected-panel {
        width: 400px;
        padding: 0px 24px;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        .gear-selected-header {
            align-self: stretch;
            color: #FFF;
            font-family: Arvo;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;            
        }
        .gear-selected-results {
            display: flex;
            flex-direction: column;
            height: 356px;
            overflow-y: auto;
            margin-right: -24px;
            gap: 8px;
            
            .selected-gear {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 16px;
                .selected-gear-details {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .image {
                        width: 80px;
                        height: 80px;
                        padding: 8px;
                        border-radius: 16px;
                        background: #fff;
                        img {
                            width: 64px;
                            height: 64px;
                        }
                    }
                    .name {
                        
                    }
                }
                .trash {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    padding: 8px;
                    background: #231F2B;
                    border-radius: 999px;
                }
            }
        }
    }
}

.gear-selection-field {
    width: 167px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .image {
        width: 167px;
        height: 167px;
        background-color: #ffffff;
        border-radius: 10px 10px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 134px;
            height: 134px;
        }
    }
    .info {
        position: relative;
        width: 100%;
        flex: 1 0 0;
        background-color: #141414;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0px 0px 10px 10px;
        padding: 8px;
        
        .name {
            align-self: stretch;
            width: 100%;
            color: #B0B0B0;
            text-align: center;
            font-family: Poppins;
            font-size: 11px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;            
        }
    }
    .controls {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 32px;
        bottom: -16px;
        .open-button {
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 24px;
            border: 1px solid #009EE7;
            background: #081A37;
            color: #009EE7;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;
        }            
    }
}

.favorites-group {
    display: flex;
    gap: 16px;
    .favorite {
        text-align: center;
    }
}

.pill {
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 999px;
    background: #081A37;
    color: #009EE7;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    text-transform: capitalize;
    cursor: pointer;

    &.active {
        color: #000;
        background: #009EE7;        
    }
}

canvas.sound-wave {
    display: none;
}